<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline" @submit="handleSubmit" :form="form">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="状态">
              <a-select placeholder="请选择" v-decorator="[
                'status',
                {
                  initialValue: queryParam.status
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="0">未审核</a-select-option>
                <a-select-option value="1">审核通过</a-select-option>
                <a-select-option value="2">审核失败</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="学历">
              <a-select placeholder="请选择" v-decorator="[
                'highest_edu',
                {
                  initialValue: queryParam.highest_edu
                }
              ]">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="option in highest_edu_type" :key="option" :value="option">{{ option }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="学校">
              <a-input v-decorator="[
                'school',
                {
                  initialValue: queryParam.school
                }
              ]" placeholder="学校" />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button htmlType="submit" :loading="submitLoading" type="primary">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">重置</a-button>
              <a @click="toggleAdvanced" style="margin-left: 8px">
                {{ advanced ? '收起' : '展开' }}
                <a-icon :type="advanced ? 'up' : 'down'" />
              </a>
            </span>
          </a-col>
          <template v-if="advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="省份">
                <a-input v-decorator="[
                  'province',
                  {
                    initialValue: queryParam.province
                  }
                ]" placeholder="省份" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="城市">
                <a-input v-decorator="[
                  'city',
                  {
                    initialValue: queryParam.city
                  }
                ]" placeholder="城市" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="区域">
                <a-input v-decorator="[
                  'area',
                  {
                    initialValue: queryParam.area
                  }
                ]" placeholder="区域" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="地址">
                <a-input v-decorator="[
                  'address',
                  {
                    initialValue: queryParam.address
                  }
                ]" placeholder="地址" />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="性别">
                <a-select placeholder="请选择" v-decorator="[
                  'sex',
                  {
                    initialValue: queryParam.sex
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="option in sex_type" :key="option" :value="option">{{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="方式">
                <a-select placeholder="请选择" v-decorator="[
                  'coaching_methods',
                  {
                    initialValue: queryParam.coaching_methods
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="option in coaching_methods_type" :key="option" :value="option">{{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="身份">
                <a-select placeholder="请选择" v-decorator="[
                  'current_status',
                  {
                    initialValue: queryParam.current_status
                  }
                ]">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option v-for="option in current_status_type" :key="option" :value="option">{{ option }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </div>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="data" :pagination="pagination"
      :loading="loading" @change="handleTableChange">
      <span slot="status" slot-scope="text">
        <a-badge :status="text | statusTypeFilter" :text="text | statusFilter" />
      </span>
      <span slot="create_time" slot-scope="text">
        {{ text ? (text | dayjs) : '' }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="goEdit(record)">查看</a>
      </span>
    </a-table>
  </a-card>
</template>
<script>

import { teacherInfoList } from '@/api/teacher'
import merge from 'webpack-merge'

const statusMap = {
  '0': {
    status: 'warning',
    text: '待审核'
  },
  '1': {
    status: 'success',
    text: '通过'
  },
  '2': {
    status: 'error',
    text: '未通过'
  }
}

const columns = [
  {
    title: '教员ID',
    dataIndex: 'relid',
    sorter: true
  },
  {
    title: '日期',
    dataIndex: 'create_time',
    scopedSlots: { customRender: 'create_time' }
  },
  {
    title: '性别',
    dataIndex: 'sex'
  },
  {
    title: '出生日期',
    dataIndex: 'birth_day',
    sorter: true
  },
  {
    title: '最高学历',
    dataIndex: 'highest_edu'
  },
  {
    title: '就读学校',
    dataIndex: 'school'
    // ellipsis: true
  },
  {
    title: '当前身份',
    dataIndex: 'current_status'
  },
  {
    title: '省份',
    dataIndex: 'province'
  },
  {
    title: '辅导方式',
    dataIndex: 'coaching_methods'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '操作',
    width: '150px',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  data() {
    return {
      description: '教员列表，查询教员',
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      // 高级搜索 展开/关闭
      advanced: false,
      // form
      form: this.$form.createForm(this),
      // 查询参数
      queryParam: {
        status: this.$route.query.status,
        highest_edu: this.$route.query.highest_edu,
        school: this.$route.query.school,
        province: this.$route.query.province,
        city: this.$route.query.city,
        area: this.$route.query.area,
        address: this.$route.query.address,
        sex: this.$route.query.sex,
        coaching_methods: this.$route.query.coaching_methods,
        current_status: this.$route.query.current_status
      },
      data: [],
      pagination: {},
      loading: false,
      columns,
      submitLoading: false,
      tableLoading: false,
      sex_type: [],
      highest_edu_type: [],
      current_status_type: [],
      coaching_methods_type: []
    }
  },
  mounted() {
    this.fetch()
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      // 带上查询参数
      const queryParam = this.form.getFieldsValue()
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        ...queryParam
      })
    },
    fetch(params = {}) {
      this.loading = true
      new Promise((resolve, reject) => {
        teacherInfoList(params).then(response => {
          if (response.status === 'success') {
            resolve(response.data)
          } else {
            reject(response)
          }
        })
      }).then(data => {
        console.log(data)
        const pagination = { ...this.pagination }
        this.loading = false
        this.data = data.list.data
        this.sex_type = data.sex_type
        this.highest_edu_type = data.highest_edu_type
        this.current_status_type = data.current_status_type
        this.coaching_methods_type = data.coaching_methods_type
        pagination.total = data.list.total
        pagination.pageSize = data.list.per_page
        pagination.current = data.list.current_page
        this.pagination = pagination
      })
    },
    resetQuery() {
      // 重置查询条件，并重新请求
      this.form.setFieldsValue({
        status: '',
        highest_edu: '',
        school: ''
      })
      if (this.advanced) {
        this.form.setFieldsValue({
          province: '',
          city: '',
          area: '',
          address: '',
          sex: '',
          coaching_methods: '',
          current_status: ''
        })
      }
      this.$router.push({
        query: merge({}, {})
      })
      this.submitLoading = true
      this.fetch()
      this.submitLoading = false
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    handleSubmit(e) {
      // 重置查询条件，并重新请求
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitLoading = true
          this.fetch(values)
          this.submitLoading = false
          this.$router.push({
            query: merge(this.$route.query, values)
          })
        }
      })
    },
    goEdit(record) {
      this.$router.push({ 'name': 'TeacherDetail', query: { id: record.relid } })
    }
  }
}
</script>
